
  .main-layout {
    .sider {
      .ant-layout-sider-zero-width-trigger{
        top: 0;
      }
      
      .side-menu {
        width: 100%;
        height: 100vh;
      }
    }

    @media only screen and (max-width: 991px) {
      .sider {
        position: fixed;
        z-index: 1000000;
      }
    }

    .body-layout {
      height: 100vh;
      overflow-y: auto;

      .content {
        max-width: 1000px;
        width: 100%;
        margin: auto;
        margin-top: 0;
        padding: 20px;
      }
    }

    @media only screen and (max-width: 991px) {
      .body-layout {
        padding-top: 30px;
      }
    }
  }