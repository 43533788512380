.rolls {
  .result-box {
    padding: 10px;

    .ant-form-item-label {
      padding-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}