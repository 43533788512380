.toggle-checkbox-group {
  width: 100%;
  flex-wrap: wrap;

  label {
    margin-inline-start: 0 !important;
  }
}

.table-collapse {
  background: none;

  .ant-collapse-header {
    padding: 0 !important;
    font-size: 20px;
  }

  .ant-collapse-expand-icon {
    margin-top: 6px;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.compressed-table {
  .ant-table-cell {
    padding: 10px 5px !important
  }
}

.search-box {
  margin-top: 10px;
}

.table-col {
  margin-top: 10px;
}

.overlimit-warn {
  color: yellow;
}

.overlimit-error {
  color: red;
}

.copy-button{
  width: 80px;
  margin: 10px 0;
}