.loots {
  .result-box {
    padding: 10px;

    .result-header {
      font-size: 25px;
      margin-bottom: 5px;
    }

    .result-sub-header {
      font-size: 20px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .ant-form-item-label {
      padding-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}