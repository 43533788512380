h1.ant-typography {
  margin-top: 20px !important;
  margin-bottom: 10px;
}

h2.ant-typography {
  margin-top: 16px !important;
  margin-bottom: 8px;
}

h3.ant-typography {
  margin-top: 12px !important;
  margin-bottom: 6px;
}

h4.ant-typography {
  margin-top: 8px !important;
  margin-bottom: 4px;
}

h5.ant-typography {
  margin-top: 4px !important;
  margin-bottom: 2px;
}

div.ant-typography {
  margin-bottom: 0;
  font-size: 16px;
}

.number-input {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 12px;
}

.readonly-form-item {
  margin-bottom: 0;
}

.button-row {
  margin-top: 10px;
}

.message-list {
  margin-top: 10px;

  .message-row {
    margin-top: 10px;

    .copy-button {
      margin-top: 5px;
    }
  }
}